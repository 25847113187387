<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-input size="small" v-model="queryForm.title" placeholder="请输入标题" clearable style="width:200px;"></el-input>
        <el-date-picker size="small" v-model="dateArray" type="datetimerange" :picker-options="pickerOptions"
          range-separator="至" start-placeholder="通知开始日期" end-placeholder="通知结束日期" align="right"
          class="input-with-select" style="width:400px;margin-left: 10px"
          value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        <el-button class="ml10" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>
        <el-button size="small" type="primary" plain icon="el-icon-plus" @click="saveEnterNote">发通知</el-button>

      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary" plain
          @click="tongbuJava">迁移老版本</el-button>

      </div>
    </el-row>
    <div class="dividerBar"></div>
    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
      <el-table-column label="标题" prop="title" min-width="200px" align="center"></el-table-column>
      <el-table-column label="发送时间" prop="send_time" width="200px" align="center"></el-table-column>
      <el-table-column label="企业数" prop="number" width="150px"></el-table-column>
      <el-table-column label="发送状态" prop="send_status" width="150px" align="center">
        <template slot-scope="scope">{{ scope.row.send_status == 1 ? "已发送" : "未发送" }}</template>
      </el-table-column>
      <el-table-column label="发送类型" prop="send_channel" width="150px" align="center"></el-table-column>
      <el-table-column label="企业选择类型" prop="pick_type" width="200px" align="center">
        <template slot-scope="scope">{{ scope.row.pick_type == "all" ? "全部" : "部分" }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-tag class="pointer ml10" type="primary" size="mini" @click="editItem(scope.row)">编辑</el-tag>
          <el-tag class="pointer ml10" type="success" size="mini" @click="showNote(scope.row)">通知</el-tag>
          <el-tag v-if="$store.state.pmid == 210" class="pointer ml10" type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog class="cus_dialog cus_dialogMax" ref="ruleForm" title="通知详细" :visible.sync="dialogForm"
      :close-on-click-modal="false" width="1000px">
      <el-table height="100%" v-tableFit :data="MsgData" row-key="id" border stripe
        style="width:fit-content;width:100%;">
        <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
        <el-table-column label="企业名称" prop="firm_name" min-width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="email" prop="email" width="200px" show-overflow-tooltip></el-table-column>
        <el-table-column label="消息状态" prop="send_status" width="90px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.send_status == 1" style="color:#67C23A;">已发送</span>
            <span v-if="scope.row.send_status == 2" style="color:grey">未发送</span>
            <span v-if="scope.row.send_status == 3" style="color:#F56C6C;">发送失败</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remarks" width="90px" align="center"></el-table-column>
        <el-table-column label="发送时间" prop="send_time" width="180px"></el-table-column>


      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dateArray: [],
      tableData: [],
      MsgData: [],
      queryForm: {
        title: ""
      },
      dialogForm: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 20,
        total_page: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http
        .post("/api/zp_enter_note_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          title: this.queryForm.title,
          start_time: this.dateArray.length > 0 ? this.dateArray[0] : "",
          end_time: this.dateArray.length > 0 ? this.dateArray[1] : ""
        })
        .then(res => {
          this.tableData = res.data.data;
          this.page = res.data.page;
        });
    },
    saveEnterNote() {
      this.$router.push("/qytz_edit");
    },
    editItem(item) {
      this.$router.push("/qytz_edit?id=" + item.id);
    },
    showNote(item) {

      this.dialogForm = true
      this.$http.post("/api/zp_enter_note_info", { id: item.id }).then(res => {
        this.MsgData = res.data.send_log
      })

    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_zp_enter_note", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    handleDelete(row) {
      this.$confirm('此操作不可恢复，确定删除？',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/zp_enter_note_delete', {id:row.id}).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
  }
};
</script>
<style scoped>
/deep/.headbar {
  height: 10px;
}
</style>
